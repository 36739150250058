export default {
  "titles": {
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["services"])},
    "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cv"])},
    "register_step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register-step-1"])},
    "register_step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register-step-2"])},
    "register_step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register-step-3"])},
    "restorepassword_check_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore password check-code"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packages"])},
    "packagesdef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packages"])},
    "appointment_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment requests"])},
    "order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orderDetails"])},
    "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My reviews"])}
  },
  "Auth": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "ungo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])},
    "tax_numberplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter tax number"])},
    "edituser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit user"])},
    "doneuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been added successfully"])},
    "doneuseredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User has been modified successfully"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activate"])},
    "geosave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The scope was saved successfully"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "emailplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter email"])},
    "mobile_and_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your mobile number and password"])},
    "phone_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number / email"])},
    "mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter Password"])},
    "docadded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The doctor has been added successfully"])},
    "docedited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The doctor has been modified successfully"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "forget_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you forget your password ?"])},
    "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "enter_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter center data"])},
    "center_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center logo"])},
    "pictures_credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures of the center"])},
    "pictures_creditsm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures of credits"])},
    "name_center_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The center name in arabic"])},
    "name_center_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The center name in english"])},
    "name_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the manager"])},
    "center_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center mobile number"])},
    "officials_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The official's mobile number"])},
    "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License number"])},
    "tax_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax number"])},
    "whats_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["whatsapp Number"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["website"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city"])},
    "ide_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Number"])},
    "ide_numberplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter ID Number"])},
    "geographical_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geographical location"])},
    "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select country"])},
    "sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sections"])},
    "the_specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The specialization"])},
    "choosespe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose The specialization"])},
    "description_experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description and experiences"])},
    "defin_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm the password"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to"])},
    "terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The terms and conditions"])},
    "already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an account"])},
    "continuation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuation"])},
    "activate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate the account"])},
    "enter_verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the verification code"])},
    "verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])},
    "not_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not receive a code"])},
    "enter_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your data"])},
    "upload_picture_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a picture of the doctor"])},
    "bank_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter bank account information"])},
    "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank name"])},
    "iban_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iban number"])},
    "account_holders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder's name"])},
    "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
    "data_sent_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data has been sent to the administration to respond to you"])},
    "restore_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore password"])},
    "enter_mobile_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your mobile number to receive the code"])},
    "enter_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the new password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "definitely_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definitely a new password"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])}
  },
  "Sidebar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "brar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch name in arabic"])},
    "brarplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter branch name in arabic"])},
    "bren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch name in English"])},
    "brenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter branch name in English"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "appointment_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment requests"])},
    "times_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["times of work"])},
    "external_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External appointments"])},
    "my_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My data"])},
    "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My reviews"])},
    "financial_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial transactions"])},
    "order_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order reports"])},
    "user_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User permissions"])},
    "about_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the application"])},
    "terms_and_Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
    "connect_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with us"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "aksir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aksir"])},
    "center_control_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center control panel"])}
  },
  "datatable": {
    "servname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service name"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
    "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user name"])},
    "usernameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter user name"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the price"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["section"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["category"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of sessions"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment"])},
    "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["picture"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])},
    "package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package name"])}
  },
  "orders": {
    "sale_Added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sale"])},
    "all_Added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total price"])},
    "new_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New appointments"])},
    "current_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current appointments"])},
    "expired_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired appointments"])},
    "new_orders_and_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see new orders and their details"])},
    "see_the_current_one_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see the current one and its details"])},
    "see_finished_orders_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see finished orders and their details"])},
    "Search_customer_name_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by order number / customer name / mobile number"])},
    "order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order number"])},
    "order_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order owner"])},
    "order_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order value"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "Booking_application_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking value(application commission)"])},
    "value_Added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Added"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "the_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the date"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finished"])},
    "finishorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finish order"])},
    "uporder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit the visit report"])},
    "notarrived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pationt doesn't arrived"])},
    "finishedor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finished order"])},
    "showrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show rate"])},
    "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payed"])},
    "defnum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Number"])},
    "new_order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New order details"])},
    "current_order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current order details"])},
    "details_completed_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of the completed order"])},
    "service_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service details"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comments"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment method"])},
    "appointment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment details"])},
    "order_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order summary"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the address"])},
    "reservation_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reservation confirmation"])},
    "suggest_another_appointment_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggest another appointment for the client"])},
    "no_records_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records found"])},
    "booking_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking date"])},
    "send_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send appointment"])},
    "appointment_sent_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The appointment has been sent to the client. You will be contacted"])},
    "amount_paid_order_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rest of the amount has been paid and the order has been started"])},
    "request_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request has been completed"])},
    "view_customer_rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View customer rating"])},
    "the_working_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the working hours"])}
  },
  "Glopal": {
    "filterby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter by"])},
    "editbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit branch"])},
    "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["found"])},
    "single_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["services"])},
    "appersdoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doctors apear here"])},
    "brshowdet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch details apear here"])},
    "det": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
    "serdoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search by doctor name"])},
    "Adddoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add doctor"])},
    "editDoctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit doctor"])},
    "conpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm password"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "gendere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nationality"])},
    "datem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["birthday"])},
    "bradded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The branch has been added successfully"])},
    "bredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The branch has been modified successfully"])},
    "uploadea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the credits images"])},
    "docnamear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doctor name in arabic"])},
    "docnamearplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter doctor name in arabic"])},
    "docnameen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doctor name in english"])},
    "docnameenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter doctor name in english"])},
    "removedoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the doctor?"])},
    "donedoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The doctor has been successfully deleted"])},
    "aduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add user"])},
    "edituser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit user"])},
    "permissionsdisplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the user permissions are displayed"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RS"])},
    "wantremove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the user?"])},
    "searchuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search by user name"])},
    "Mypointsmainplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see your points"])},
    "Mypointsmainor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to transfer points"])},
    "namebranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch name"])},
    "pointadds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra points"])},
    "Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors"])},
    "brshow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branches show here"])},
    "brshowlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have exceeded the maximum number of branches allowed"])},
    "addbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add branch"])},
    "editbranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit branch"])},
    "spbranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate the branch"])},
    "spbranchdel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate the branch"])},
    "relateddocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["related doctors"])},
    "homeser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides home service"])},
    "donerem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The branch was deleted successfully"])},
    "removecon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the branch?"])},
    "DoctorsDet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the doctor's details are displayed"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["point"])},
    "pointall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total point"])},
    "pointdone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request has been sent successfully"])},
    "Theuserhasbeendeletedsuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been removed successfully"])},
    "homeservicesplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the details of the home visiting service"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the terms and conditions"])},
    "timepack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The timeline for completing the package"])},
    "branches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branches"])},
    "visitprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visit price"])},
    "visitpriceplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter visit price"])},
    "geo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geographic scope"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current loaction"])},
    "scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search for scope"])},
    "branchesplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose branches"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount"])},
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not found"])},
    "the_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the message"])},
    "Please_enter_your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter maessage"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actions"])},
    "filterbycat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter by departmnet"])},
    "searchpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search by package name"])},
    "searchservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search by service name"])},
    "addpackimg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload package image"])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no data to show"])},
    "semm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semm"])},
    "priceall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total price"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["newest"])},
    "packadd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add pachages"])},
    "packedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit pachages"])},
    "pricest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The most expensive"])},
    "most_ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the most ordered"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center control panel"])},
    "clinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clinic control panel"])},
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch control panel"])},
    "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doctor control panel"])},
    "baseinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business information"])},
    "Servicemanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service management"])},
    "myservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My services"])},
    "manageorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order management"])},
    "myorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
    "Couponmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon management"])},
    "Coupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my Coupons"])},
    "Specialoffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special offers and discounts"])},
    "Specialoffersmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special offers"])},
    "Financialtransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial transactions"])},
    "Financialtransactionsdisplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here private financial transactions are displayed"])},
    "Otherservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other services"])},
    "homeservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home visiting service"])},
    "Administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["management"])},
    "Doctorsmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors management"])},
    "branchesmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branches  management"])},
    "Mypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My points"])},
    "Mypointsmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the points"])},
    "aboutSemm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about Semm"])},
    "clinic_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clinic data"])},
    "semmclinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can modify the clinic data"])},
    "semmcenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can modify the center data"])},
    "control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["control panel"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "statistics_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the statistics are displayed"])},
    "total_number_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of reservations"])},
    "new_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New reservations"])},
    "current_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current reservations"])},
    "expired_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired reservations"])},
    "number_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of services"])},
    "number_packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of packages"])},
    "services_offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the services offered"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "cancel_appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel appearance"])},
    "hide_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide price"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
    "add_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add service"])},
    "upload_picture_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a picture of the service"])},
    "service_name_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service name in arabic"])},
    "service_name_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service name in english"])},
    "service_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service price"])},
    "choose_service_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter service price"])},
    "doctor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor's name"])},
    "choose_doctor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the doctor's name"])},
    "select_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select section"])},
    "select_sectionplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please Select section"])},
    "packlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package list"])},
    "packlistplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose Package list"])},
    "select_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the category"])},
    "choose_number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the number of sessions"])},
    "service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service description"])},
    "enter_service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the service description"])},
    "terms_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
    "choose_terms_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the terms of service"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
    "available_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the service available for installments ?"])},
    "service_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service has been added successfully"])},
    "service_edited_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service has been modified successfully"])},
    "do_you_want_delete_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the service?"])},
    "service_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service has been deleted successfully"])},
    "edit_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit service"])},
    "picture_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["picture of the service"])},
    "customer_reviews_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer reviews on the service"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
    "highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest price"])},
    "lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest price"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unavailable"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "to_be_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to be sure"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancellation"])},
    "packages_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the packages displayed"])},
    "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add package"])},
    "upload_picture_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a photo of the package"])},
    "package_name_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The package name is in Arabic"])},
    "package_name_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package name in English"])},
    "package_name_arabicplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The package name is in Arabic"])},
    "package_name_englishplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package name in English"])},
    "package_expiry_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package expiry date"])},
    "select_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select services"])},
    "Price_total_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price of total services"])},
    "Price_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price of  services"])},
    "discount_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount percentage"])},
    "discount_percentageplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter discount percentage"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage"])},
    "enter_discount_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the discount percentage"])},
    "percentage_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The discount percentage must be less than the price of the services"])},
    "rs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rs"])},
    "package_descriptionar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package description in arabic"])},
    "package_descriptionarplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the package description in arabic"])},
    "package_descriptionen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package description in english"])},
    "package_descriptionenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the package description in english"])},
    "package_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package terms"])},
    "enter_package_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the package terms"])},
    "package_available_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the package available for installments?"])},
    "Choose_gender_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the gender the package is intended for"])},
    "want_delete_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the package?"])},
    "package_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The package has been deleted successfully"])},
    "edit_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit package"])},
    "save_edits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save edits"])},
    "price_after_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price after discount"])},
    "package_modified_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The package has been modified successfully"])},
    "customer_reviews_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer reviews on the package"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friday"])},
    "customer_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer reviews"])},
    "clinic_evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic evaluation"])},
    "service_evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service evaluation"])},
    "working_periods_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the working periods are displayed"])},
    "outpatient_clinic_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outpatient clinic appointments"])},
    "clinic_outpatient_appointments_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the clinic's outpatient appointments"])},
    "book_appointment_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to book an appointment slot?"])},
    "appointment_booked_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The appointment has been booked successfully"])},
    "reservation_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the reservation duration"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vacation"])},
    "modify_central_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can modify the central data"])},
    "center_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center data"])},
    "Doctors_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor's data"])},
    "bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account"])},
    "here_modify_center_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can modify the center data"])},
    "here_edit_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can edit your data"])},
    "modify_bank_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can modify the bank account information"])},
    "center_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center name"])},
    "current_Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
    "new_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New mobile number"])},
    "old_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["old mobile number"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])},
    "settlementrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send settlement request"])},
    "Change_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change mobile number"])},
    "mobile_number_changed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mobile number has been changed successfully"])},
    "modifications_saved_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modifications were saved successfully"])},
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
    "password_changed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password has been changed successfully"])},
    "image_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of a doctor"])},
    "name_doctor_Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of the doctor in Arabic"])},
    "name_doctor_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor's name in English"])},
    "no_available_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no available options"])},
    "Cliniclogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic logo"])},
    "Clinicphotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic photos"])},
    "yourwalletbalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wallet balance"])},
    "details_about_the_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find details about the application"])},
    "branchword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch"])},
    "choosebranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select branch"])},
    "customer_reviews_presented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here customer reviews are presented to you"])}
  },
  "newtrans": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
    "tradeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade Information"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "choosereg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Account Type"])},
    "clinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Center"])},
    "loglogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Image"])},
    "centername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic Name in Arabic"])},
    "centernameen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic Name in English"])},
    "numbranches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Branches"])},
    "numbranchessign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Number of Branches"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "clientname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search by  client name"])},
    "nameclient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client name"])},
    "paytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment type"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
    "cityplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select the City"])},
    "speoffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Offers"])},
    "speoffersphase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see the special offers and their details"])},
    "addoffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Offer"])},
    "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doctor"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
    "confirmoffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the offer?"])},
    "doneoffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer deleted successfully"])},
    "doneofferadd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers added successfully"])},
    "clinicchoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose from Clinic Packages"])},
    "centerchoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose from Center Packages"])},
    "packconditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Conditions in Arabic"])},
    "packconditionsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Conditions in English"])},
    "sercond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["service conditions"])},
    "serviceduration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Duration (in Hours)"])},
    "servicedurationplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Service Duration in Hours"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "numspe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Units"])},
    "docchoosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Doctor"])},
    "datechoosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Date"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "servicecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Conditions in Arabic"])},
    "servicecondplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Service Conditions"])},
    "serviceconden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Conditions in English"])},
    "servicecondenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Service Conditions"])},
    "servicedetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description in Arabic"])},
    "servicedetailsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description in English"])},
    "servicedetailsenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Description in English"])},
    "servicedetailsplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Description in Arabic"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "chooseservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Service"])},
    "packconditionsenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Package Conditions"])},
    "addpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Package"])},
    "editpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Package"])},
    "donepack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package added successfully"])},
    "doneservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service added successfully"])},
    "doneeditservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service edited successfully"])},
    "doneeditpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package edited successfully"])},
    "packdet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Description"])},
    "packcond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Conditions"])},
    "alltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Duration"])},
    "theservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "addservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Services"])},
    "addservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Service"])},
    "editservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Service"])},
    "editservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Services"])},
    "imgservicesup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Service Image"])},
    "downloadexcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Excel Template"])},
    "expoprtexcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import from Excel"])},
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions"])},
    "serviceconditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Conditions"])},
    "ordersphase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see the orders and their details"])},
    "filtercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Status"])},
    "filtertime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Appointment Time"])},
    "filterway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Payment Methods"])},
    "datebegin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Start Date"])},
    "dateend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose End Date"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
    "uploadfileplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the file here"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Payment"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash"])},
    "doctorrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Rating"])},
    "packrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Rating"])},
    "norates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ratings yet"])},
    "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
    "orderreports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Reports"])},
    "orderreportsplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see the order reports"])},
    "nopack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no packages to show"])},
    "packconditionspalce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Package Conditions"])}
  },
  "fainanceTrans": {
    "cancontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can contact the administration"])},
    "copouns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupons"])},
    "copounsphase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can view the coupons and their details"])},
    "addcoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Coupon"])},
    "filtertype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Type"])},
    "filtersale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Discount Amount"])},
    "filterstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Status"])},
    "filtername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by Coupon Name"])},
    "confirmcop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the coupon?"])},
    "confirmcopremove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon deleted successfully"])},
    "copdet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Details"])},
    "addcop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Coupon"])},
    "editcop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Coupon"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "codeplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "nameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Type"])},
    "typeplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the coupon type"])},
    "copsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Amount"])},
    "copsaleplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the discount amount"])},
    "datedeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "datebegplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the start date"])},
    "dateend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "dateendplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the end date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "statusactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "statusdeactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "addDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon added successfully"])},
    "editDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon edited successfully"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratio"])},
    "typecop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
    "cancontactplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the username"])}
  },
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome from en"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ٌRamadan"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "Newroute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NewRoute"])},
  "tesssst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["testtt en"])}
}