<template>
  <header
    class="main-header"
    :class="{ active: isActive, activeNav: !AuthRoute }"
  >
    <div class="top-nav">
      <div class="container">
        <div class="main-nav">
          <div class="logo-img" v-if="AuthRoute">
            <img
              :src="require('@/assets/images/nav-logo.png')"
              alt="nav-logo"
            />
          </div>

          <div class="navbar-btn" v-else @click="toggleActive">
            <img
              class="sidmenu-btn"
              :src="require('@/assets/images/menu_1.png')"
              alt="sidmenu-btn"
            />
          </div>

          <div class="left d-flex gap-3">
            <div class="d-flex gap-3 align-items-center" v-if="!AuthRoute">
              <div class="dropdown">
                
                <a
                  class="dropdown-toggle hint-lang pro-hint gap-1 d-flex"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img :src="userLogo" alt="img" class="pro-img" />
                  <div class="disable-mob">{{ nameLogged }}</div>
                </a>

                <ul class="dropdown-menu drop-pro">
                  <div class="text-center color-orange p-2">{{ nameLogged }}</div>
                  <div class="text-center p-2 border-b b-bottom">{{ email }}</div>
                  <router-link
                    to="/myData/centerData"
                    v-if="
                      (user_type == 'clinic' || user_type == 'center') &&
                      allAbilities
                    "
                    class="dropdown-item b-bottom"
                  >
                    <div>{{ $t("newtrans.tradeinfo") }}</div>
                    <div class="pro-icon">
                      <i class="fa-regular fa-user"></i>
                    </div>
                  </router-link>
                  <router-link
                    to="/myDataDoctor"
                    v-if="user_type == 'doctor'"
                    class="dropdown-item b-bottom"
                  >
                    <div>{{ $t("newtrans.tradeinfo") }}</div>
                    <div class="pro-icon">
                      <i class="fa-regular fa-user"></i>
                    </div>
                  </router-link>
                  <router-link
                    to="/myDataBranch"
                    v-if="user_type == 'branch'"
                    class="dropdown-item b-bottom"
                  >
                    <div>المعلومات التجارية</div>
                    <div class="pro-icon">
                      <i class="fa-regular fa-user"></i>
                    </div>
                  </router-link>
                  <button
                    class="dropdown-item red"
                    :disabled="disabled"
                    @click="logOut($event)"
                  >
                    <div>{{ $t("newtrans.logout") }}</div>
                    <div class="spinner-small-white red" v-if="disabled"></div>
                    <div class="pro-icon trans" v-if="!disabled">
                      <i class="fa-solid fa-arrow-right-from-bracket"></i>
                    </div>
                  </button>
                </ul>
              </div>
              <router-link :to="{ name: 'notifications' }">
                <div class="notif-icon" :data-number="notificationCount">
                  <img
                    class="bell-img"
                    :src="require('@/assets/images/notification-bing.png')"
                    alt="bell-img"
                  />
                </div>
              </router-link>
            </div>

            <div
              class="hint-lang pointer-click"
              @click="switchLang('en')"
              v-if="myLang == 'ar'"
            >
              <div>EN</div>
              <div class="color-orange-lang">
                <i class="fas fa-globe px-2"></i>
              </div>
            </div>
            <div
              class="hint-lang pointer-click"
              @click="switchLang('ar')"
              v-else
            >
              <div>AR</div>
              <div class="color-orange-lang">
                <i class="fas fa-globe px-2"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      AuthRoute: true,
      htmlLang: "",
      AllRoutes: [],
      onlyNavbar: [],
      itemToggle: false,
      navBtnActive: false,
      navLinksActive: false,
      navOverlayShow: false,
      isActive: false,
      disabled: false,
      notificationCount: "",
      nameLogged: "",
      userLogo: "",
      user_type: "",
      allAbilities: null,
      abilities: null,
      myLang : 'ar',
      email : '',
    };
  },

  inject: ["emitter"],

  methods: {
    async logOut(e) {
      e.stopPropagation();
      this.disabled = true;
      const fd = new FormData();

      await axios
        .post("/provider/sign-out", fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data.key == "success") {
            localStorage.removeItem("token");
            localStorage.removeItem("user_type");
            localStorage.removeItem("phone");
            localStorage.removeItem("country_code");
            localStorage.removeItem("userLogged");

            this.$toast.add({
              severity: "success",
              detail: `${response.data.msg}`,
              life: 3000,
            });

            this.$router.push({ name: "login" });
          } else {
            this.$toast.add({
              severity: "error",
              detail: `${response.data.msg}`,
              life: 3000,
            });
          }
          this.disabled = false;
          
        });
    },
    toggleActive() {
      this.$emit("toggle-active");
      this.isActive = !this.isActive;
    },

    chageDir(dir) {
      let element = document.querySelector(".html_direction");
      element.setAttribute("lang", dir);
      if (dir == "ar") {
        console.log("dir ar");
        element.setAttribute("dir", "rtl");
        this.htmlLang = dir;
      }
      if (dir == "en") {
        element.setAttribute("dir", "ltr");
        this.htmlLang = dir;
        console.log(this.htmlLang, "en html");
      }
    },

    switchLang(newLang) {
      // this.language = newLang;
      localStorage.setItem("locale", newLang);
      location.reload();
    },
    async getNotiCount() {
      await axios
        .get("/provider/count-notifications", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data.key != "unauthenticated") {
            this.notificationCount = response.data.data.count;
          } else {
            this.$router.push("/login");
            localStorage.removeItem("token");
          }
        });
    },
    checkSuperVisor() {
      if (this.abilities == "*") {
        this.allAbilities = true;
      } else {
        this.allAbilities = false;
      }
    },
  },

  mounted() {
    if (localStorage.getItem("userLogged")) {
      this.getNotiCount();
    }
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "ar");
    }
    this.AllRoutes = this.$router.options.routes;

    // single rout
    this.onlyNavbar = this.$router.options.routes.filter(
      (item) => item.slug == "navBar"
    );

    window.sessionKey = localStorage.getItem("locale");
    if (sessionKey) {
      console.log("he is not null");
      this.chageDir(localStorage.getItem("locale"));
    }
    this.nameLogged = localStorage.getItem("user_name");
    this.userLogo = localStorage.getItem("userLogo");
    this.user_type = localStorage.getItem("user_type");
    this.email = localStorage.getItem("email");
  },

  watch: {
    // Watch for changes in the route
    $route(to, from) {
      // Handle the event here
      // console.log("Route changed:", to, from);
      if (!localStorage.getItem("locale")) {
        localStorage.setItem("locale", "ar");
      }

      this.AuthRoute = to.meta.authRout;
      if (localStorage.getItem("userLogged")) {
        this.getNotiCount();
      }
      this.nameLogged = localStorage.getItem("user_name");
      this.userLogo = localStorage.getItem("userLogo");
      this.user_type = localStorage.getItem("user_type");
      this.myLang = localStorage.getItem("locale");
      this.email = localStorage.getItem("email");
      if (localStorage.getItem("abilities")) {
        this.abilities = JSON.parse(localStorage.getItem("abilities"));
      }
      this.checkSuperVisor();
    },
  },
};
</script>

<style lang="scss">
.main-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 99;
}

.navbar-btn {
  cursor: pointer;
  .sidmenu-btn {
    max-width: 32px;
  }
}
</style>
