export default {
  "titles": {
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
    "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيرة الزاتية"])},
    "register_step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل-الخطوة-1"])},
    "register_step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل-الخطوة-2"])},
    "register_step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل-الخطوة-3"])},
    "restorepassword_check_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور التحقق من الرمز"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباقات"])},
    "packagesdef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باقات"])},
    "appointment_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات"])},
    "order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب"])},
    "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييماتى"])}
  },
  "Auth": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل دخول"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمرار"])},
    "edituser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل المستخدم"])},
    "geosave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ النطاق بنجاح"])},
    "doneuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة المستخدم بنجاح"])},
    "doneuseredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل المستخدم بنجاح"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
    "choosespe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التخصص"])},
    "ungo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء تفعيل"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل خدمة الزيارة"])},
    "mobile_and_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الجوال وكلمة المرور"])},
    "phone_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال / البريد الإلكترونى"])},
    "mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "passwordplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور"])},
    "docadded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة الطبيب بنجاح"])},
    "docedited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل الطبيب بنجاح"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "emailplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل البريد الالكتروني"])},
    "forget_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة المرور ؟"])},
    "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب ؟"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
    "enter_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بيانات المركز"])},
    "center_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعار المركز"])},
    "pictures_credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور المركز"])},
    "pictures_creditsm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور الاعتمادات"])},
    "name_center_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المركز باللغة العربية"])},
    "name_center_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المركز باللغة الانجليزية"])},
    "name_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المسئول"])},
    "center_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم جوال المركز"])},
    "officials_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم جوال المسئول"])},
    "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الرخصة"])},
    "whats_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الواتساب"])},
    "ide_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهوية"])},
    "ide_numberplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال رفم الهوية"])},
    "tax_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الضريبى"])},
    "tax_numberplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال الرقم الضريبي"])},
    "geographical_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع الجغرافي"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع الالكتروني"])},
    "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر دولة"])},
    "sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقسام"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "the_specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
    "description_experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف والخبرات"])},
    "defin_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موافق على"])},
    "terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والاحكام"])},
    "already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالفعل لديك حساب"])},
    "continuation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمرار"])},
    "activate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل حساب"])},
    "enter_verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل كود التحقق"])},
    "verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التحقق"])},
    "not_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يصلك كود"])},
    "enter_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بياناتك"])},
    "upload_picture_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صورة الطبيب"])},
    "bank_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بيانات الحساب البنكي"])},
    "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البنك"])},
    "iban_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الآيبان"])},
    "account_holders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم صاحب الحساب"])},
    "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الحساب"])},
    "data_sent_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال بياناتك للإدارة سيتم الرد عليك"])},
    "restore_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور"])},
    "enter_mobile_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رقم الجوال لتتلقى الكود"])},
    "enter_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل كلمة المرور الجديدة"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
    "definitely_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور الجديدة"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])}
  },
  "Sidebar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباقات"])},
    "brar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفرع باللغة العربية"])},
    "brarplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الفرع باللغة العربية"])},
    "bren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفرع باللغة الانجليزية"])},
    "brenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الفرع باللغة الانجليزية"])},
    "appointment_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات المواعيد"])},
    "times_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواعيد العمل"])},
    "external_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواعيد الخارجية"])},
    "my_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بياناتي"])},
    "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييماتي"])},
    "financial_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملات المالية"])},
    "order_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الطلبات"])},
    "user_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحيات المستخدم"])},
    "about_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن التطبيق"])},
    "terms_and_Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
    "connect_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل خروج"])},
    "aksir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكسير"])},
    "center_control_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة تحكم المركز"])}
  },
  "datatable": {
    "servname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الخدمة"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الجلسات"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقسيط"])},
    "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة"])},
    "package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقة"])},
    "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "usernameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المستخدم"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصلاحيات"])}
  },
  "orders": {
    "new_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواعيد الجديدة"])},
    "current_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواعيد الحالية"])},
    "expired_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواعيد المنتهية"])},
    "new_orders_and_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الطلبات الجديدة وتفاصيلها"])},
    "see_the_current_one_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الحالية وتفاصيلها"])},
    "see_finished_orders_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الطلبات المنتهية وتفاصيلها"])},
    "Search_customer_name_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث برقم الطلب /اسم العميل / رقم الجوال"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطلب"])},
    "order_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صاحب الطلب"])},
    "order_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الطلب"])},
    "Booking_application_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الحجز(عمولة التطبيق)"])},
    "value_Added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة المضافة"])},
    "sale_Added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الخصم"])},
    "all_Added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الاجمالي"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالى"])},
    "the_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جديد"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالي"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهي"])},
    "finishorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انهاء الطلب"])},
    "uporder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع تقرير الزيارة"])},
    "notarrived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يصل المريض"])},
    "finishedor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب منتهي"])},
    "showrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التقييم"])},
    "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الدفع"])},
    "defnum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم التسلسلي"])},
    "new_order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب الجديد"])},
    "current_order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب الحالي"])},
    "details_completed_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب المنتهي"])},
    "service_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الخدمة"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة الدفع"])},
    "appointment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الموعد"])},
    "order_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص الطلب"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "reservation_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الحجز"])},
    "suggest_another_appointment_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقتراح موعد آخر للعميل"])},
    "no_records_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يعثر على أية سجلات"])},
    "booking_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد الحجز"])},
    "send_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الموعد"])},
    "appointment_sent_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال الموعد للعميل سيتم الرد عليك"])},
    "amount_paid_order_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم دفع باقي المبلغ وبدأ الطلب"])},
    "request_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الانتهاء من الطلب"])},
    "view_customer_rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض تقييم العميل"])},
    "the_working_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض مواعيد العمل"])}
  },
  "Glopal": {
    "filterby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية حسب"])},
    "brshow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الفروع"])},
    "brshowdet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض تفاصيل الفرع"])},
    "editbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل فرع"])},
    "det": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل"])},
    "addbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة فرع"])},
    "homeser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يقدم خدمة منزلية"])},
    "donerem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الفرع بنجاح"])},
    "relateddocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاطباء التابعين"])},
    "removecon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بالفعل تريد حذف الفرع؟"])},
    "brshowlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تعديت العدد الاقصى المسموح من الفروع"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رس"])},
    "editDoctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل طبيب"])},
    "uploadea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صور الاعتمادات"])},
    "docnamear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطبيب بالعربية"])},
    "docnamearplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الطبيب بالعربية"])},
    "docnameen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطبيب بالانجليزية"])},
    "docnameenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الطبيب بالانجليزية"])},
    "Adddoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة طبيب"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
    "bradded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة الفرع بنجاح"])},
    "bredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة الفرع بنجاح"])},
    "conpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد كلمة المرور"])},
    "removedoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بالتاكيد تريد حذف الطبيب؟"])},
    "donedoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الطبيب بنجاح"])},
    "appersdoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الاطباء"])},
    "serdoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث ب اسم الطبيب"])},
    "timepack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت الزمني لاتمام الباقة"])},
    "wantremove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد بالفعل حذف المستخدم؟"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحدث"])},
    "packadd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الباقات"])},
    "packedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الباقات"])},
    "addpackimg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صورة الباقة"])},
    "pricest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاغلى"])},
    "most_ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاكثر طلبا"])},
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد"])},
    "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يوجد"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجراءات"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية"])},
    "filterbycat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية حسب القسم"])},
    "searchpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث ب اسم الباقة"])},
    "searchservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث ب اسم الخدمة"])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد بيانات لعرضها"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "semm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سمّ"])},
    "semmclinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك تعديل بيانات العيادة"])},
    "semmcenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك تعديل بيانات المركز"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة تحكم المركز"])},
    "control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
    "clinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة تحكم العيادة"])},
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة تحكم الفرع"])},
    "branchword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرع"])},
    "choosebranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الفرع"])},
    "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة تحكم الطبيب"])},
    "baseinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات التجارية"])},
    "Servicemanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الخدمات"])},
    "myservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتي"])},
    "manageorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الطلبات"])},
    "myorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتي"])},
    "Couponmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الكوبونات"])},
    "Coupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كوبوناتي"])},
    "Specialoffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض والتخفيضات الخاصة"])},
    "Specialoffersmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض الخاصة"])},
    "Financialtransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملات المالية"])},
    "Otherservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات اخرى"])},
    "aboutSemm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن سم"])},
    "homeservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة الزيارة المنزلية"])},
    "homeservicesplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض تفاصيل خدمة الزيارة المنزلية"])},
    "Administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الادارة"])},
    "visitprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الزيارة"])},
    "visitpriceplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل سعر الزيارة"])},
    "geo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النطاق الجغرافي"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقعك الحالي"])},
    "scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن نطاق"])},
    "Doctorsmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الاطباء"])},
    "Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاطباء"])},
    "DoctorsDet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض تفاصيل الطبيب"])},
    "branchesmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الفروع"])},
    "branches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفروع"])},
    "editbranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الفرع"])},
    "spbranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل الفرع"])},
    "spbranchdel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء تفعيل الفرع"])},
    "branchesplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الفروع"])},
    "Mypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقاطي"])},
    "Mypointsmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقاط"])},
    "namebranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفرع"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقطة"])},
    "pointadds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقاط اضافية"])},
    "pointall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع النقاط"])},
    "pointdone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال الطلب بنجاح"])},
    "Mypointsmainor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب تحويل نقاط"])},
    "Mypointsmainplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية النقاط الخاصة بك"])},
    "statistics_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الإحصائيات"])},
    "total_number_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحجوزات الكلي"])},
    "new_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجوزات الجديدة"])},
    "current_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجوزات الحالية"])},
    "expired_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجوزات المنتهية"])},
    "number_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الخدمات"])},
    "number_packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الباقات"])},
    "services_offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الخدمات"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "cancel_appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء ظهور"])},
    "hide_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إخفاء السعر"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييمات"])},
    "add_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة خدمة"])},
    "upload_picture_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صورة الخدمة"])},
    "service_name_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الخدمة باللغة العربية"])},
    "service_name_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الخدمة باللغة الانجليزية"])},
    "service_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الخدمة"])},
    "choose_service_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل سعر الخدمة"])},
    "doctor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطبيب"])},
    "choose_doctor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر اسم الطبيب"])},
    "select_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر القسم"])},
    "select_sectionplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار القسم"])},
    "select_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التصنيف"])},
    "packlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الباقة"])},
    "packlistplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر قائمة الباقة"])},
    "choose_number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر عدد الجلسات"])},
    "service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الخدمة"])},
    "enter_service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وصف الخدمة"])},
    "terms_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الخدمة"])},
    "choose_terms_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل شروط الخدمة"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
    "available_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل الخدمة متاحة للتقسيط ؟"])},
    "service_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إضافة الخدمة بنجاح"])},
    "service_edited_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل الخدمة بنجاح"])},
    "do_you_want_delete_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بالفعل تريد حذف الخدمة؟"])},
    "service_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الخدمة بنجاح"])},
    "edit_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل خدمة"])},
    "picture_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الخدمة"])},
    "customer_reviews_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييمات العملاء على الخدمة"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييما"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر"])},
    "highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعلى سعر"])},
    "lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقل  سعر"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير متاح"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
    "gendere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])},
    "datem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنثى"])},
    "to_be_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء"])},
    "packages_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الباقات"])},
    "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة باقة"])},
    "upload_picture_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صورة الباقة"])},
    "package_name_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقة باللغة العربية"])},
    "package_name_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقة باللغة الانجليزية"])},
    "package_name_arabicplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر اسم الباقة باللغة العربية"])},
    "package_name_englishplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر اسم الباقة باللغة الانجليزية"])},
    "package_expiry_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انتهاء الباقة"])},
    "select_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الخدمات"])},
    "Price_total_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الخدمات الكلي"])},
    "Price_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الخدمات"])},
    "discount_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الخصم"])},
    "discount_percentageplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل نسبة الخصم"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة"])},
    "enter_discount_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل نسبة الخصم"])},
    "percentage_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان تكون نسبة الخصم اقل من سعر الخدمات"])},
    "rs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ر.س"])},
    "package_descriptionar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الباقة بالعربية"])},
    "package_descriptionarplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الوصف بالعربية"])},
    "package_descriptionen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الباقة بالانجليزية"])},
    "package_descriptionenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الوصف بالانجليزية"])},
    "enter_package_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وصف الباقة"])},
    "package_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الباقة"])},
    "enter_package_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل شروط الباقة"])},
    "package_available_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل الباقة متاحة للتقسيط؟"])},
    "Choose_gender_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الجنس الموجه له الباقة"])},
    "want_delete_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بالفعل تريد حذف الباقة؟"])},
    "package_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الباقة بنجاح"])},
    "edit_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل باقة"])},
    "save_edits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التعديلات"])},
    "price_after_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر بعد الخصم"])},
    "priceall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الكلي"])},
    "package_modified_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل الباقة بنجاح"])},
    "customer_reviews_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييمات العملاء على الباقة"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحد"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاثنين"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثلاثاء"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأر بعاء"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخميس"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمعة"])},
    "customer_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييمات العميل"])},
    "clinic_evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم على العيادة"])},
    "service_evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم الخدمة"])},
    "working_periods_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض فترات العمل"])},
    "outpatient_clinic_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواعيد الخارجية للعيادة"])},
    "clinic_outpatient_appointments_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض المواعيد الخارجية للعيادة"])},
    "book_appointment_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حجز فترة موعد؟"])},
    "appointment_booked_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حجز الموعد بنجاح"])},
    "reservation_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل مدة الحجز"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعة"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجازة"])},
    "modify_central_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك تعديل البيانات المركزية"])},
    "center_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المركز"])},
    "Doctors_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطبيب"])},
    "clinic_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات العيادة"])},
    "bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب البنكي"])},
    "here_modify_center_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك تعديل بيانات المركز"])},
    "here_edit_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك تعديل بياناتك"])},
    "modify_bank_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك تعديل بيانات الحساب البنكي"])},
    "center_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المركز"])},
    "current_Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية"])},
    "new_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال الجديد"])},
    "old_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال القديم"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
    "Change_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير رقم الجوال"])},
    "mobile_number_changed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم نغيير رقم الجوال بنجاح"])},
    "modifications_saved_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ التعديلات بنجاح"])},
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة"])},
    "password_changed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم نغيير كلمة المرور بنجاح"])},
    "image_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الطبيب"])},
    "name_doctor_Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطبيب باللغة العربية"])},
    "name_doctor_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطبيب باللغة الإنجليزية"])},
    "no_available_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد خيارات لعرضها"])},
    "Cliniclogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعار العيادة"])},
    "Clinicphotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور العيادة"])},
    "customer_reviews_presented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض تقييمات العملاء عليك"])},
    "Financialtransactionsdisplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض المعاملات المالية الخاصة بك"])},
    "yourwalletbalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رصيد محفظتك"])},
    "settlementrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب تسوية"])},
    "settlement_request_sent_dministration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال طلب التسوية للإدارة وسيتم الرد عليك"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
    "download_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل pdf"])},
    "download_xl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل xl"])},
    "reports_orders_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض تقارير عن طلباتك"])},
    "permissionsdisplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض صلاحيات المستخدمين"])},
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مستخدم"])},
    "searchuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث ب اسم المستخدم"])},
    "package_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الباقات"])},
    "Service_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الخدمات"])},
    "order_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الطلبات"])},
    "appointment_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة المواعيد"])},
    "user_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إضافة المستخدم بنجاح"])},
    "do_you_want_to_delete_the_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بالفعل تريد حذف المستخدم؟"])},
    "Theuserhasbeendeletedsuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف المستخدم بنجاح"])},
    "edituser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل مستخدم"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشعارات"])},
    "here_see_the_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الاشعارات"])},
    "no_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد اشعارات"])},
    "communicate_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك التواصل مع الإدارة"])},
    "the_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
    "Please_enter_your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رسالتك"])},
    "details_about_the_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض تفاصيل عن التطبيق"])},
    "Here_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الشروط والأحكام"])},
    "there_no_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مواعيد"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اظهار"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسات"])},
    "aduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مستخدم"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الشروط والاحكام"])},
    "single_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات"])}
  },
  "newtrans": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة"])},
    "tradeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات التجارية"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "paytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الدفع"])},
    "choosereg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر نوع الحساب"])},
    "clinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عيادة"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مركز طبي"])},
    "loglogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الشعار"])},
    "centername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العيادة باللغة العربية"])},
    "centernameen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العيادة باللغة الانجليزية"])},
    "numbranches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفروع"])},
    "numbranchessign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الفروع"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "cityplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء  اختيار المدينة"])},
    "speoffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض الخاصة"])},
    "speoffersphase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية العروض الخاصة وتفاصيلها"])},
    "addoffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة عرض جديد"])},
    "confirmoffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بالفعل تريد حذف العرض؟"])},
    "doneoffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف العرض بنجاح"])},
    "doneofferadd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة العروض بنجاح"])},
    "clinicchoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر من باقات العيادة"])},
    "centerchoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر من باقات المركز"])},
    "packconditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الباقة بالعربية"])},
    "packconditionsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الباقة بالانجليزي"])},
    "serviceduration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الخدمة (بالساعات)"])},
    "servicedurationplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل مدة الخدمة بالساعات"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات"])},
    "numspe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الوحدات"])},
    "docchoosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطبيب المختار"])},
    "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطبيب"])},
    "datechoosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموعد المختار"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
    "servicecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الخدمة بالعربية"])},
    "servicecondplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل شروط الخدمة"])},
    "serviceconden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الخدمة بالانجليزية"])},
    "servicecondenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل شروط الخدمة"])},
    "servicedetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف بالعربية"])},
    "servicedetailsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف بالانجليزية"])},
    "servicedetailsenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الوصف بالانجليزية"])},
    "servicedetailsplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الوصف بالعربية"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمة"])},
    "chooseservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الخدمة"])},
    "packconditionsenplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل شروط الباقة"])},
    "addpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الباقة"])},
    "editpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الباقة"])},
    "donepack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة الباقة بنجاح"])},
    "doneservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة الخدمة بنجاح"])},
    "doneeditservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل الخدمة بنجاح"])},
    "doneeditpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل الباقة بنجاح"])},
    "packdet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الباقة"])},
    "packcond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الباقة"])},
    "sercond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ِشروط الخدمة"])},
    "alltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة الكلية"])},
    "theservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
    "addservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الخدمات"])},
    "addservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الخدمة"])},
    "editservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الخدمة"])},
    "editservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الخدمات"])},
    "imgservicesup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صورة الخدمة"])},
    "downloadexcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنزيل القالب Excel"])},
    "expoprtexcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استيراد من Excel"])},
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط"])},
    "serviceconditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الخدمة"])},
    "ordersphase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الطلبات وتفاصيلها"])},
    "filtercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية على حسب الحالة"])},
    "filtertime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية حسب موعد الزيارة"])},
    "filterway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية حسب طرق الدفع"])},
    "datebegin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر تاريخ البدا"])},
    "dateend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر تاريخ الانتهاء"])},
    "clientname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث ب اسم العميل"])},
    "nameclient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العميل"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
    "uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع الملف"])},
    "uploadfileplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع الملف من هنا"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محفظة"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع الكتروني"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كاش"])},
    "doctorrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم الطبيب"])},
    "packrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم الباقة"])},
    "norates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد تقييمات حتى الان"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايام"])},
    "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطبيب"])},
    "orderreports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الطلبات"])},
    "orderreportsplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية تقارير الطلبات"])},
    "nopack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد باقات لعرضها"])},
    "packconditionspalce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل شروط الباقة"])}
  },
  "fainanceTrans": {
    "cancontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك التواصل مع الادارة"])},
    "copouns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكوبونات"])},
    "copounsphase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الكوبونات وتفاصيلها"])},
    "addcoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة كوبون"])},
    "filtertype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية حسب النوع"])},
    "typecop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" النوع"])},
    "filtersale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية حسب مقدار التخفيض"])},
    "filterstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية على حسب الحالة"])},
    "filtername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث ب اسم الكوبون"])},
    "confirmcop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بالفعل تريد حذف الكوبون؟"])},
    "confirmcopremove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الكوبون بنجاح"])},
    "copdet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الكوبون"])},
    "addcop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة كوبون"])},
    "editcop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الكوبون"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود"])},
    "codeplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الكود"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "nameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الاسم"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الكوبون"])},
    "typeplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر نوع الكوبون"])},
    "copsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدار التخفيض"])},
    "copsaleplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل مقدار التخفيض"])},
    "datedeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البدء"])},
    "datebegplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر تاريخ البدء"])},
    "dateend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
    "dateendplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر تاريخ الانتهاء"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "statusactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
    "statusdeactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
    "addDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة الكوبون بنجاح"])},
    "editDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل الكوبون بنجاح"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية"])},
    "ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة"])},
    "cancontactplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم المستخدم"])}
  },
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربي 2222"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمضان"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصى"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن"])},
  "Newroute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط جديد"])},
  "tesssst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["testtt ar"])}
}