<template>
  <div class="sidebar" v-if="!AuthRoute" :class="{ active: isActive }">
    <div class="colse-icon" @click="$emit('toggle-active')">
      <i class="fas fa-times"></i>
    </div>

    <div class="sidebar-logo mb-0 b-bottom">
      <router-link :to="{ name: 'Home' }">
        <img :src="require('@/assets/images/nav-logo.png')" alt="nav-logo" />
      </router-link>

      <div class="sidebar-text">
        <p class="color-main">{{ $t("Glopal.semm") }}</p>
        <p v-if="user_type == 'clinic'">{{ $t("Glopal.clinic") }}</p>
        <p v-else-if="user_type == 'branch'">{{ $t("Glopal.branch") }}</p>
        <p v-else-if="user_type == 'doctor'">{{ $t("Glopal.doctor") }}</p>
        <p v-else>{{ $t("Glopal.center") }}</p>
      </div>
    </div>

    <div class="title-def b-bottom mb-3">{{ $t("Glopal.control") }}</div>

    <ul ref="linksList" class="links">
      <router-link
        class="link"
        ref="HomeLink"
        :to="{ name: 'Home' }"
        :class="{ active: isLinkActive('Home') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/home-img.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.home") }}
        </div>
      </router-link>
      <router-link
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
        class="link mb-0"
        ref="myDataLink"
        :to="{ name: 'myData' }"
        :class="{ active: isLinkActive('myData') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/hashtag.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.baseinfo") }}
        </div>
      </router-link>
      <router-link
        v-if="user_type == 'doctor'"
        class="link mb-0"
        ref="myDataDoctorLink"
        :to="{ name: 'myDataDoctor' }"
        :class="{ active: isLinkActive('myDataDoctor') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/hashtag.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.baseinfo") }}
        </div>
      </router-link>
      <!--branch data-->
      <router-link
        v-if="user_type == 'branch'"
        class="link mb-0"
        ref="myDataBranchLink"
        :to="{ name: 'myDataBranch' }"
        :class="{ active: isLinkActive('myDataBranch') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/hashtag.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.baseinfo") }}
        </div>
      </router-link>
      <div
        class="title-def b-bottom mb-3"
        v-if="allAbilities || showPackages || showServices"
      >
      {{ $t("Glopal.Servicemanagement") }}
      </div>
      <!--center clinic-->
      <router-link
        v-if="
          (user_type == 'clinic' || user_type == 'center') &&
          (allAbilities || showPackages)
        "
        class="link"
        ref="packagesLink"
        :to="{ name: 'packages' }"
        :class="{ active: isLinkActive('packages') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/award.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.packages") }}
        </div>
      </router-link>

      <router-link
        v-if="
          (user_type == 'clinic' || user_type == 'center') &&
          (allAbilities || showServices)
        "
        class="link mb-0"
        ref="servicesLink"
        :to="{ name: 'services' }"
        :class="{ active: isLinkActive('services') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/fatrows.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.myservices") }}
        </div>
      </router-link>
      <!--doctor-->
      <router-link
        v-if="user_type == 'doctor'"
        class="link"
        ref="packagesDoctorLink"
        :to="{ name: 'packagesDoctor' }"
        :class="{ active: isLinkActive('packagesDoctor') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/award.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.packages") }}
        </div>
      </router-link>
      <!--branch-->
      <router-link
        v-if="user_type == 'branch'"
        class="link"
        ref="packagesBranchLink"
        :to="{ name: 'packagesBranch' }"
        :class="{ active: isLinkActive('packagesBranch') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/award.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.packages") }}
        </div>
      </router-link>

      <router-link
        v-if="user_type == 'doctor'"
        class="link mb-0"
        ref="servicesDoctorLink"
        :to="{ name: 'servicesDoctor' }"
        :class="{ active: isLinkActive('servicesDoctor') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/fatrows.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.myservices") }}
        </div>
      </router-link>
      <router-link
        v-if="user_type == 'branch'"
        class="link mb-0"
        ref="servicesBranchLink"
        :to="{ name: 'servicesBranch' }"
        :class="{ active: isLinkActive('servicesBranch') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/fatrows.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.myservices") }}
        </div>
      </router-link>
      <div class="title-def b-bottom mb-3" v-if="allAbilities || showOrders">
        {{ $t("Glopal.manageorders") }}
      </div>
      <!--center clinic-->
      <router-link
        v-if="
          (user_type == 'clinic' || user_type == 'center') &&
          (allAbilities || showOrders)
        "
        class="link"
        ref="neworderLink"
        :to="{ name: 'neworder' }"
        :class="{
          active: isLinkActive('neworder') || $route.name === 'orderDetails',
        }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/task-square.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.myorders") }}
        </div>
      </router-link>
      <!--branch-->
      <router-link
        v-if="user_type == 'branch'"
        class="link"
        ref="neworderLink"
        :to="{ name: 'neworderBranch' }"
        :class="{
          active:
            isLinkActive('neworderBranch') || $route.name === 'orderDetails',
        }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/task-square.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.myorders") }}
        </div>
      </router-link>
      <!--doctor-->
      <router-link
        v-if="user_type == 'doctor'"
        class="link"
        ref="neworderDoctorLink"
        :to="{ name: 'neworderDoctor' }"
        :class="{
          active:
            isLinkActive('neworderDoctor') || $route.name === 'orderDetails',
        }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/task-square.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.myorders") }}
        </div>
      </router-link>

      <div
        class="title-def b-bottom mb-3"
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
      >
        {{ $t("Glopal.Couponmanagement") }}
      </div>

      <router-link
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
        class="link"
        ref="myCopounsLink"
        :to="{ name: 'myCopouns' }"
        :class="{ active: isLinkActive('myCopouns') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/Vectorco.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.Coupons") }}
        </div>
      </router-link>

      <div
        class="title-def b-bottom mb-3"
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
      >
      {{ $t("Glopal.Specialoffers") }}
      </div>
      <router-link
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
        class="link"
        ref="specialOffersLink"
        :to="{ name: 'specialOffers' }"
        :class="{ active: isLinkActive('specialOffers') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/Groupspe.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.Specialoffersmain") }}
        </div>
      </router-link>

      <div
        class="title-def b-bottom mb-3"
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
      >
      {{ $t("Glopal.Financialtransactions") }}
      </div>

      <router-link
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
        class="link"
        ref="financialTransactionsLink"
        :to="{ name: 'financialTransactions' }"
        :class="{ active: isLinkActive('financialTransactions') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="
              require('@/assets/images/sidebar-img/empty-wallet-change.png')
            "
            alt="nav-logo"
          />
          {{ $t("Sidebar.financial_transactions") }}
        </div>
      </router-link>

      <div
        class="title-def b-bottom mb-3"
        v-if="( user_type == 'center' && haveService) && allAbilities"
      >
      {{ $t("Glopal.Otherservices") }}
      </div>

      <router-link
        v-if="(user_type == 'center' && haveService) && allAbilities"
        class="link"
        ref="homeServiceLink"
        :to="{ name: 'homeService' }"
        :class="{ active: isLinkActive('homeService') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/sates.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.homeservices") }}
        </div>
      </router-link>

      <div class="title-def b-bottom mb-3" v-if="allAbilities || showTimes">
        {{ $t("Sidebar.times_work") }}
      </div>

      <router-link
        v-if="user_type == 'doctor'"
        class="link"
        ref="worktimeDoctorLink"
        :to="{ name: 'worktimeDoctor' }"
        :class="{ active: isLinkActive('worktimeDoctor') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/clock.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.times_work") }}
        </div>
      </router-link>
      <router-link
        v-if="user_type != 'doctor' && (allAbilities || showTimes)"
        class="link"
        ref="worktimeLink"
        :to="{ name: 'worktime' }"
        :class="{ active: isLinkActive('worktime') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/clock.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.times_work") }}
        </div>
      </router-link>

      <div
        class="title-def b-bottom mb-3"
        v-if="user_type != 'doctor' && allAbilities"
      >
      {{ $t("Glopal.Administration") }}
      </div>
      <!--center clinic-->
      <router-link
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
        class="link"
        ref="doctorsLink"
        :to="{ name: 'doctors' }"
        :class="{ active: isLinkActive('doctors') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/people.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.Doctorsmanagement") }}
        </div>
      </router-link>
      <router-link
        v-if="user_type == 'branch'"
        class="link"
        ref="doctorsBranchLink"
        :to="{ name: 'doctorsBranch' }"
        :class="{ active: isLinkActive('doctorsBranch') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/people.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.Doctorsmanagement") }}
        </div>
      </router-link>

      <router-link
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
        class="link"
        ref="branches"
        :to="{ name: 'branches' }"
        :class="{ active: isLinkActive('branches') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/buildings-2.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.branchesmanagement") }}
        </div>
      </router-link>

      <div
        class="title-def b-bottom mb-3"
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
      >
      {{ $t("Glopal.Mypoints") }}
      </div>
      <router-link
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
        class="link"
        ref="pointsLink"
        :to="{ name: 'points' }"
        :class="{ active: isLinkActive('points') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/coin.png')"
            alt="nav-logo"
          />
          {{ $t("Glopal.Mypointsmain") }}
        </div>
      </router-link>

      <div class="title-def b-bottom mb-3">  {{ $t("Sidebar.my_reviews") }}</div>

      <router-link
        class="link"
        ref="myReviewsLink"
        :to="{ name: 'myReviews' }"
        :class="{ active: isLinkActive('myReviews') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/star.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.my_reviews") }}
        </div>
      </router-link>
      <div
        class="title-def b-bottom mb-3"
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
      >
      {{ $t("Sidebar.order_reports") }}
      </div>
      <router-link
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
        class="link"
        ref="orderReportsLink"
        :to="{ name: 'orderReports' }"
        :class="{ active: isLinkActive('orderReports') }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/receipt-item.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.order_reports") }}
        </div>
      </router-link>

      <div
        class="title-def b-bottom mb-3"
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
      >
      {{ $t("Sidebar.user_permissions") }}
      </div>

      <router-link
        v-if="(user_type == 'clinic' || user_type == 'center') && allAbilities"
        class="link"
        :class="{ active: isLinkActive('userPermissions') }"
        :to="{ name: 'userPermissions' }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/profile-2user.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.user_permissions") }}
        </div>
      </router-link>

      <div class="title-def b-bottom mb-3"> {{ $t("Glopal.aboutSemm") }}</div>

      <router-link
        class="link"
        :class="{ active: isLinkActive('About') }"
        :to="{ name: 'About' }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/warning-2.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.about_application") }}
        </div>
      </router-link>

      <router-link
        class="link"
        :class="{ active: isLinkActive('termsConditions') }"
        :to="{ name: 'termsConditions' }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/note-2.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.terms_and_Conditions") }}
        </div>
      </router-link>

      <router-link
        class="link"
        :class="{ active: isLinkActive('Connectus') }"
        :to="{ name: 'Connectus' }"
        @click="handleResize"
      >
        <div class="hint-img">
          <img
            :src="require('@/assets/images/sidebar-img/message-text.png')"
            alt="nav-logo"
          />
          {{ $t("Sidebar.connect_us") }}
        </div>
      </router-link>
    </ul>
  </div>

  <div
    class="overlay-sidebar"
    @click="$emit('toggle-active')"
    :class="{ show: isActive }"
  ></div>
</template>

<script>
import axios from "axios";
export default {
  props: ["isActive"],
  data() {
    return {
      AuthRoute: true,
      user_type: "",
      allAbilities: false,

      // ******
      abilities: null,
      showOrders: false,
      showPackages: false,
      showServices: false,
      showTimes: false,
      haveService: false,
    };
  },

  mounted() {
    // On component mount, check if there is a stored active link
    const storedActiveLink = localStorage.getItem("activeLink");

    if (storedActiveLink) {
      // Scroll to the stored active link
      this.scrollToActiveLink(storedActiveLink);
    }
  },
  beforeMount() {
    this.abilities = JSON.parse(localStorage.getItem("abilities"));
  },
  methods: {
    async getCategories() {
      await axios
        .get("/categories", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          for(let i = 0  ; i < response.data.data.length ; i++){
            if(response.data.data[i].id == 2){
              this.haveService = true
            }
          }

        });
    },
    emptyValues() {
      this.showOrders = false;
      this.showServices = false;
      this.showPackages = false;
      this.showTimes = false;
    },
    checkSuperVisor() {
      this.emptyValues();
      if (localStorage.getItem("abilities")) {
        if (this.abilities == "*") {
          this.allAbilities = true;
        } else {
          this.allAbilities = false;
          if (this.abilities.some((e) => e.value == "manage-orders")) {
            this.showOrders = true;
          }
          if (this.abilities.some((e) => e.value == "manage-packages")) {
            this.showPackages = true;
          }
          if (this.abilities.some((e) => e.value == "manage-services")) {
            this.showServices = true;
          }
          if (this.abilities.some((e) => e.value == "manage-work-time")) {
            this.showTimes = true;
          }
        }
      }
    },
    isLinkActive(route) {
      const isActive = this.$route.matched.some(
        (record) => record.name === route
      );

      if (isActive) {
        const refName = `${route}Link`;
        this.scrollToActiveLink(refName);

        // Store the active link in localStorage
        localStorage.setItem("activeLink", refName);
      }

      return isActive;
    },

    scrollToActiveLink(refName) {
      // Wait for the next tick to ensure that the DOM has been updated
      this.$nextTick(() => {
        const linkRef = this.$refs[refName];
        // Scroll to the active link with smooth behavior
        if (linkRef && linkRef.$el) {
          linkRef.$el.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      });
    },
  },

  watch: {
    $route(to, from) {
      this.AuthRoute = to.meta.authRout;
      this.user_type = localStorage.getItem("user_type");
      if (localStorage.getItem("abilities")) {
        this.abilities = JSON.parse(localStorage.getItem("abilities"));
      }
      this.checkSuperVisor();
      if(localStorage.getItem('token') && localStorage.getItem('user_type') == 'center'){
        this.getCategories();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.colse-icon {
  cursor: pointer;
}
.links {
  scroll-behavior: smooth;
}
</style>
