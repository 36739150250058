import { createRouter, createWebHistory } from "vue-router";
import i18n from "@/i18n";

// start to sidebar route

const routes = [
  // home Route
  {
    name: "Home",
    path: "/",
    slug: "navBar",
    component: () => import("../views/index.vue"),
    meta: {
      title: "Glopal.home",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // home services

  {
    name: "services",
    path: "/services",
    slug: "navBar",
    component: () => import("../views/services.vue"),
    meta: {
      title: "titles.services",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "servicesBranch",
    path: "/servicesBranch",
    slug: "navBar",
    component: () => import("../views/servicesBranch.vue"),
    meta: {
      title: "titles.services",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "servicesDoctor",
    path: "/servicesDoctor",
    slug: "navBar",
    component: () => import("../views/servicesDoctor.vue"),
    meta: {
      title: "titles.services",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  // myCopouns
  {
    name: "myCopouns",
    path: "/myCopouns",
    slug: "navBar",
    component: () => import("../views/myCopouns.vue"),
    meta: {
      title: "كوبوناتي",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "addCopoun",
    path: "/addCopoun/:id",
    slug: "navBar",
    component: () => import("../views/addCopoun.vue"),
    meta: {
      title: "كوبوناتي",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  // specialOffers Route
  {
    name: "specialOffers",
    path: "/specialOffers",
    slug: "navBar",
    component: () => import("../views/specialOffers.vue"),
    meta: {
      title: "العروض الخاصة",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  // points Route
  {
    name: "points",
    path: "/points",
    slug: "navBar",
    component: () => import("../views/points.vue"),
    meta: {
      title: "النقاط",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  // branches Route
  {
    name: "branches",
    path: "/branches",
    slug: "navBar",
    component: () => import("../views/branches.vue"),
    meta: {
      title: "الفروع",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "addBranch",
    path: "/addBranch/:id",
    slug: "navBar",
    component: () => import("../views/addBranch.vue"),
    meta: {
      title: "الفروع",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "branchDetails",
    path: "/branchDetails/:id",
    slug: "navBar",
    component: () => import("../views/branchDetails.vue"),
    meta: {
      title: "الفروع",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // packages Route

  {
    name: "packages",
    path: "/packages",
    slug: "navBar",
    component: () => import("../views/packages.vue"),
    meta: {
      title: "titles.packages",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "packagesDoctor",
    path: "/packagesDoctor",
    slug: "navBar",
    component: () => import("../views/packagesDoctor.vue"),
    meta: {
      title: "titles.packages",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "packagesBranch",
    path: "/packagesBranch",
    slug: "navBar",
    component: () => import("../views/packagesBranch.vue"),
    meta: {
      title: "titles.packages",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "packagedDetails",
    path: "/packagedDetails/:id",
    slug: "navBar",
    component: () => import("../views/packagedDetails.vue"),
    meta: {
      title: "titles.packages",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "offerDetails",
    path: "/offerDetails/:id",
    slug: "navBar",
    component: () => import("../views/offerDetails.vue"),
    meta: {
      title: "العروض",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "addNewPackage",
    path: "/addNewPackage/:id",
    component: () => import("@/views/addNewPackage.vue"),
    meta: {
      title: "titles.packages",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  // addsevice Route

  {
    name: "addNewService",
    path: "/addNewService/:id",
    slug: "navBar",
    component: () => import("../views/addNewService.vue"),
    meta: {
      title: "الخدمات",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // appointment Requests Route

  {
    name: "neworderBranch",
    path: "/neworderBranch",
    component: () => import("@/views/Orders/neworderBranch.vue"),
    meta: {
      title: "titles.appointment_requests",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "orderDetailsBranch",
    path: "/orderDetailsBranch/:id",
    component: () => import("@/views/Orders/orderDetailsBranch.vue"),
    meta: {
      title: "titles.appointment_requests",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "orderDetailsDoctor",
    path: "/orderDetailsDoctor/:id",
    component: () => import("@/views/Orders/orderDetailsDoctor.vue"),
    meta: {
      title: "titles.appointment_requests",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "neworderDoctor",
    path: "/neworderDoctor",
    component: () => import("@/views/neworderDoctor.vue"),
    meta: {
      title: "titles.appointment_requests",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "neworder",
    path: "/neworder",
    component: () => import("@/views/Orders/newOrderstable.vue"),
    meta: {
      title: "titles.appointment_requests",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "orderDetails",
    path: "/orderDetails/:id",
    component: () => import("@/views/Orders/orderDetails.vue"),
    meta: {
      title: "titles.appointment_requests",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // product details route

  {
    name: "orderDetails",
    path: "/orderDetails/:id",
    slug: "navBar",
    component: () => import("@/views/Orders/orderDetails.vue"),
    meta: {
      title: "titles.order_details",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  // home service route

  {
    name: "homeService",
    path: "/homeService",
    slug: "navBar",
    component: () => import("@/views/homeService.vue"),
    meta: {
      title: "خدمة الزيارة المنزلية",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // Notifications
  {
    name: "notifications",
    path: "/notifications",
    slug: "navBar",
    component: () => import("@/views/notifications.vue"),
    meta: {
      title: "الاشعارات",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // Worktime
  {
    name: "worktime",
    path: "/worktime",
    slug: "navBar",
    component: () => import("@/views/workTime.vue"),
    meta: {
      title: "Sidebar.times_work",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "worktimeDoctor",
    path: "/worktimeDoctor",
    slug: "navBar",
    component: () => import("@/views/worktimeDoctor.vue"),
    meta: {
      title: "Sidebar.times_work",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // Doctors
  {
    name: "doctors",
    path: "/doctors",
    slug: "navBar",
    component: () => import("@/views/doctors.vue"),
    meta: {
      title: "الاطباء",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "doctorsBranch",
    path: "/doctorsBranch",
    slug: "navBar",
    component: () => import("@/views/doctorsBranch.vue"),
    meta: {
      title: "الاطباء",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "addDoctorBranch",
    path: "/addDoctorBranch/:id",
    slug: "navBar",
    component: () => import("@/views/addDoctorBranch.vue"),
    meta: {
      title: "الاطباء",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "addDoctor",
    path: "/addDoctor/:id",
    slug: "navBar",
    component: () => import("@/views/addDoctor.vue"),
    meta: {
      title: "الاطباء",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "doctorDetails",
    path: "/doctorDetails/:id",
    slug: "navBar",
    component: () => import("@/views/doctorDetails.vue"),
    meta: {
      title: "الاطباء",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // My Data

  {
    name: "myDataBranch",
    path: "/myDataBranch",
    slug: "navBar",
    component: () => import("@/views/myDataBranch.vue"),
    meta: {
      title: "Sidebar.my_data",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "myDataDoctor",
    path: "/myDataDoctor",
    slug: "navBar",
    component: () => import("@/views/myDataDoctor.vue"),
    meta: {
      title: "Sidebar.my_data",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "myData",
    path: "/myData",
    slug: "navBar",
    component: () => import("@/views/myData.vue"),
    redirect: { name: "centerData" },
    meta: {
      title: "Sidebar.my_data",
      descreption: "bla bla bla",
      requiresAuth: true,
    },

    children: [
      {
        name: "centerData",
        path: "centerData",
        component: () => import("@/views/MyData/centerData.vue"),
      },
      {
        name: "changePhone",
        path: "changePhone",
        component: () => import("@/views/MyData/changePhone.vue"),
      },
      {
        name: "activatePhone",
        path: "activatePhone",
        component: () => import("@/views/MyData/activatePhone.vue"),
      },
      {
        name: "bankAccount",
        path: "bankAccount",
        component: () => import("@/views/MyData/bankAccount.vue"),
      },
    ],
  },

  // My reviews
  {
    name: "myReviews",
    path: "/myReviews",
    slug: "navBar",
    component: () => import("@/views/myReviews.vue"),
    meta: {
      title: "titles.my_reviews",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // My Financial transactions
  {
    name: "financialTransactions",
    path: "/financialTransactions",
    slug: "navBar",
    component: () => import("@/views/financialTransactions.vue"),
    meta: {
      title: "Sidebar.financial_transactions",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // Order reports
  {
    name: "orderReports",
    path: "/orderReports",
    slug: "navBar",
    component: () => import("@/views/orderReports"),
    meta: {
      title: "Sidebar.order_reports",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // test routes

  // serviceTest
  {
    name: "serviceTest",
    path: "/serviceTest",
    slug: "navBar",
    component: () => import("@/views/datatableTestFile/service_test"),
    meta: {
      title: "service_test",
      descreption: "bla bla bla",
    },
  },

  // serviceTest
  {
    name: "package_test",
    path: "/package_test",
    slug: "navBar",
    component: () => import("@/views/datatableTestFile/package_test"),
    meta: {
      title: "package_test",
      descreption: "bla bla bla",
    },
  },

  // start to Auth
  {
    name: "login",
    path: "/login",
    slug: "Auth",
    component: () => import("@/views/Auth/login"),
    meta: {
      title: "Auth.signin",
      descreption: "bla bla bla",
      authRout: true,
    },
  },

  {
    name: "register-step-1",
    path: "/register-step-1",
    slug: "Auth",
    component: () => import("@/views/Auth/registerStep_1"),
    meta: {
      title: "titles.register_step_1",
      descreption: "bla bla bla",
      authRout: true,
    },
  },

  {
    name: "register-step-2",
    path: "/register-step-2",
    slug: "Auth",
    component: () => import("@/views/Auth/registerStep_2"),
    meta: {
      title: "titles.register_step_2",
      descreption: "bla bla bla",
      authRout: true,
    },
  },

  {
    name: "register-step-3",
    path: "/register-step-3",
    slug: "Auth",
    component: () => import("@/views/Auth/registerStep_3"),
    meta: {
      title: "titles.register_step_3",
      descreption: "bla bla bla",
      authRout: true,
    },
  },

  {
    name: "activateAccount",
    path: "/activateAccount",
    slug: "Auth",
    component: () => import("@/views/Auth/activateAccount"),
    meta: {
      title: "Auth.activate_account",
      descreption: "bla bla bla",
      authRout: true,
    },
  },

  {
    name: "restorepassword",
    path: "/restorepassword",
    slug: "Auth",
    component: () => import("@/views/Auth/restorePassword"),
    meta: {
      title: "Auth.restore_password",
      descreption: "bla bla bla",
      authRout: true,
    },
  },

  {
    name: "restorepassword-check-code",
    path: "/restorepassword-check-code",
    slug: "Auth",
    component: () => import("@/views/Auth/restorePassword_check_code"),
    meta: {
      title: "titles.restorepassword_check_code",
      descreption: "bla bla bla",
      authRout: true,
    },
  },

  {
    name: "changepassword",
    path: "/changepassword",
    slug: "Auth",
    component: () => import("@/views/Auth/changePassword"),
    meta: {
      title: "titles.change_password",
      descreption: "bla bla bla",
      authRout: true,
    },
  },

  {
    name: "About",
    path: "/about",
    slug: "navBar",
    component: () => import("../views/aboutPage.vue"),
    meta: {
      title: "About",
      descreption: "bla bla bla",
    },
  },

  // terms_and_Conditions
  {
    name: "termsConditions",
    path: "/termsConditions",
    slug: "navBar",
    component: () => import("@/views/termsConditions"),
    meta: {
      title: "Auth.terms_conditions",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // Connect us
  {
    name: "Connectus",
    path: "/Connectus",
    slug: "navBar",
    component: () => import("@/views/Connectus"),
    meta: {
      title: "Sidebar.connect_us",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  // User permissions
  {
    name: "addPemission",
    path: "/addPemission/:id",
    slug: "navBar",
    component: () => import("@/views/addPemission"),
    meta: {
      title: "Sidebar.user_permissions",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },
  {
    name: "userPermissions",
    path: "/userPermissions",
    slug: "navBar",
    component: () => import("@/views/userPermissions"),
    meta: {
      title: "Sidebar.user_permissions",
      descreption: "bla bla bla",
      requiresAuth: true,
    },
  },

  {
    name: "Profile",
    path: "/profile/:userId/:userName",
    slug: "navBar",
    component: () => import("../views/profilePage.vue"),
    meta: {
      title: "Profile",
      descreption: "bla bla bla",
    },
  },

  // page not found  // page not found
  {
    name: "NotFound",
    path: "/:pathMatch(.*)*",
    component: () => import("../views/NotFound.vue"),
    meta: {
      title: "NotFound",
      descreption: "bla bla bla",
      authRout: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((auth) => auth.meta.requiresAuth)) {
    if (!localStorage.getItem("token")) {
      router.push("/login");
    } else {
      next();
    }
  } else {
    next();
  }
  const pageTitle = to.meta.title; // Access the meta title from the route
  document.title = i18n.global.t(`${pageTitle}`);
  // next();
});

export default router;
